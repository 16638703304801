import React from 'react';
import { observer } from 'mobx-react';

import { TextOptInFormViewState } from '~/text-opt-in/Components/TextOptInForm';
import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';
import { Button } from '~/components/Buttons/Components/Button';
import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const MMSOptInModal = observer((props = {}) => {
	const {
		headingText = '',
		textOptInFormStore = {},
		textOptInFormStore: {
			isSubmitted,
		},
	} = props;

	const {
		magicModal: {
			closeModal,
		} = {},
	} = useGlobalContext();

	return (
		<div data-tr-link-event-comp-name="sign up for text for exclusive updates" data-qa="mms-opt-in-modal" data-selector="modal-container">
			<S7Image src={`${s7ImagePath}/MMS_Optin_Modal`} width={660} height={363} priority alt="" />
			<div className="tw-p-6 tw-pb-10 tw-space-y-4" data-allow-tr-link-event>
				{
					headingText &&
					<h1 data-qa="mms-modal-headline" className="tw-heading-4 md:tw-heading-3 tw-mb-2">
						{headingText}
					</h1>
				}
				<TextOptInFormViewState textOptInFormStore={textOptInFormStore} />
				{isSubmitted &&
					<Button variant={ButtonVariant.Primary} data-qa="close-modal" data-tr-link-event-track="false" onClick={closeModal}>Close</Button>
				}
			</div>
		</div>
	);
});
