import React from 'react';
import { observer } from 'mobx-react';

import { EmailSignUpFormViewState } from '~/email-signup/Components/EmailSignUpForm';
import { noop } from '~/util/noop';

import styles from '~/email-signup/Components/email-mms-modal.module.scss';
import { TextOptInFormViewState } from '~/text-opt-in/Components/TextOptInForm';
import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';
import { Button } from '~/components/Buttons/Components/Button';
import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const EmailMMSSignUpModal = observer((props = {}) => {
	const {
		clickHandler = noop,
		emailSignUpFormStore = {},
		emailSignUpFormStore: {
			isSubmitted: isEmailFormSubmitted = false,
		} = {},
		headingText = '',
		subheadingText = '',
		textOptInFormStore = {},
		textOptInFormStore: {
			isSubmitted: isTextFormSubmitted = false,
		} = {},
	} = props;

	const {
		magicModal: {
			closeModal,
		} = {},
	} = useGlobalContext();

	return (
		<div className={styles['email-mms-modal']} data-tr-link-event-comp-name="be the first to know" data-selector="modal-container" data-qa="email-mms-modal">
			<div className={styles['heading-container']}>
				{
					headingText &&
					<h1 className="tw-heading-3 tw-text-white tw-mb-2">
						{headingText}
					</h1>
				}

				{
					subheadingText &&
					<p className="tw-text-white">
						{subheadingText}
					</p>
				}
			</div>

			<div className="tw-p-6 tw-space-y-4" data-allow-tr-link-event>
				<div className="tw-flex tw-flex-row tw-space-x-3 tw-relative">
					<S7Image src={`${s7ImagePath}/email`} width={31} height={27} priority className="tw-max-w-[31px] tw-max-h-[27px]" alt="" />
					<EmailSignUpFormViewState emailSignUpFormStore={emailSignUpFormStore} clickHandler={clickHandler} />
				</div>
				<div className="tw-flex tw-flex-row tw-space-x-3">
					<S7Image src={`${s7ImagePath}/MMS`} width={32} height={25} priority className="tw-max-w-[32px] tw-max-h-[25px]" alt="" />
					<TextOptInFormViewState textOptInFormStore={textOptInFormStore} clickHandler={clickHandler} />
				</div>
				{isEmailFormSubmitted && isTextFormSubmitted &&
					<Button data-qa="close-modal" variant={ButtonVariant.Primary} data-tr-link-event-track="false" onClick={closeModal}>Close</Button>
				}
			</div>
		</div>
	);
});
