import React from 'react';

import { TextOptInFormFields } from '~/text-opt-in/Types/TextOptIn.type';

export const emailSignupFormId = 'email-opt-in';
export const emailPreferencesFormId = 'email-preferences-manage';
export const emailUnsubscribeFormId = 'email-unsubscribe';

export enum TextOptInPageViewState {
	Loading = 'Loading',
	OptIn = 'OptIn',
	Success = 'Success',
	Error = 'Error',
}

export enum TextOptInFormVariant {
	STANDALONE_PAGE = 'STANDALONE_PAGE',
	EMAIL_MMS_MODAL = 'EMAIL_MMS_MODAL',
	MMS_MODAL = 'MMS_MODAL',
}

export enum TextOptInSubscriptionSource {
	STANDALONE_WEB = 'STANDALONE_WEB',
	EANDM_WEB = 'EANDM_WEB',
	MMS_WEB = 'MMS_WEB',
}

export enum PreferencesFormState {
	Initial = 'Initial',
	Saved = 'Saved',
	Submitting = 'Submitting',
}

export const textOptInFormFields: TextOptInFormFields = {
	mobilePhone: {
		control: {
			reactProps: {
				'type': 'tel',
				'autoComplete': 'tel-national',
				'data-qa': 'mobile-phone-input-field',
				'maxLength': 12,
				'pattern': '[0-9]*',
				'inputMode': 'tel',
			},
		},
		label: {
			reactProps: {
				children: 'U.S. Mobile Number',
			},
		},
		name: 'mobilePhone',
		settings: {
			validationConstraints: {
				presence: {
					message: '^Please enter a valid U.S. phone number.',
				},
				phoneUS: true,
				length: {
					is: 12,
					message: '^Please enter a valid U.S. phone number.',
				},
			},
			plugins: [
				{
					pluginId: 'maskJQ',
					settings: {
						mask: '999-999-9999',
						pattern: '[0-9]*',
						enableTwoWayData: true,
					},
				},
			],
		},
	},
	optIn: {
		control: {
			reactProps: {
				type: 'checkbox',
			},
		},
		label: {
			reactProps: {
				children: (
					<span className={'tw-font-semibold sm:tw-leading-snug'}>
						<span className={'tw-font-normal'}>By entering my mobile number and checking this box, I agree to receive marketing text messages (e.g. new products, clearance events, design inspiration) from Room & Board.</span>
					</span>
				)
			},
		},
		name: 'textOptIn'
	},
};
