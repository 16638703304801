import React from 'react';

import {
	s7ImagePath,
} from '~/global/global.constants';
import { LazyPicture } from '~/util/Components/LazyPicture';

export const FreeDesignServicesModalDefault = (props) => {
	const {
		interactEventHandler,
	} = props;

	return (
		<div>
			<div className="tw-block md:tw-flex tw-items-center">
				<div className="tw-w-full md:tw-w-1/2">
					<LazyPicture
						smallSrcSet={`${s7ImagePath}/fdsModal_sm_0724?size=428,265&scl=1&cb, ${s7ImagePath}/fdsModal_sm_0724?size=856,530&scl=1&cb 2x`}
						mediumSrcSet={`${s7ImagePath}/fdsModal_0724?size=360,335&scl=1, ${s7ImagePath}/fdsModal_0724?size=720,670&scl=1 2x`}
						fallbackSrc={`${s7ImagePath}/fdsModal_0724?size=360,335&scl=1`} />
				</div>
				<div className="fds-copy tw-w-full md:tw-w-1/2">
					<h2 className="tw-heading-3 fds-headline">Need Some Advice?</h2>
					<p className="tw-body-2">Get free, expert design help by phone, email or virtual video conference.</p>
					<a className="HollowButton HollowButton--gray tw-font-semibold" href="/free-design-services" onClick={interactEventHandler}>Make An Appointment</a>
				</div>
			</div>
		</div>
	);
};
