import React from 'react';
import { observer } from 'mobx-react';

import { Button } from '~/components/Buttons/Components/Button';
import { CheckboxField } from '~/util/formz/Components/fields/CheckboxField';
import { isOnServer } from '~/global/global.constants';
import { Field } from '~/util/formz/Components/fields/Field';
import { MaskedField } from '~/util/formz/Components/fields/MaskedField';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { TextOptInStore } from '~/text-opt-in/Stores/TextOptIn.store';

export const TextOptInFormViewState = observer(({ textOptInFormStore }: { textOptInFormStore: TextOptInStore }) => {
	if (!textOptInFormStore.viewState) {
		return null;
	}
	return <ViewState viewState={textOptInFormStore.viewState} />;
});

export const TextOptInForm = observer(({ store }: { store: TextOptInStore }) => {
	const {
		form: {
			fields: {
				mobilePhone = '',
				optIn = false,
			} = {},
			reactProps = {},
		} = {},
		disableSignUpBtn = true,
		isEmailMMSModalVariant,
		showOptInCheckbox,
		showOptInFinePrint,
		showSignUpButton,
	} = store;

	const prefix = () => {
		return (
			<div className="tw-text-gray-300 tw-border-gray-400 tw-border tw-border-r-0 tw-px-2 tw-h-[27px] tw-float-left tw-leading-[25px]">
				+1
			</div>
		);
	};

	return (
		<form className="tw-mb-7" {...reactProps} data-allow-tr-link-event>
			<div data-qa="sign-up-texts-phone-field">
				{isOnServer && <Field field={mobilePhone} beforeControl={prefix()} />}
				{!isOnServer && <MaskedField field={mobilePhone} beforeControl={prefix()} />}
			</div>
			<div aria-live={isEmailMMSModalVariant ? 'polite' : undefined}>
				{showOptInCheckbox &&
					<div data-tr-link-event-track={false}>
						<CheckboxField field={optIn} disabled={false} />
					</div>
				}
				{showOptInFinePrint &&
					<div aria-live={!isEmailMMSModalVariant ? 'polite' : undefined} className="tw-text-xs md:tw-text-2xs tw-text-gray-300 tw-mb-6" data-qa="opt-in-texts-fine-print">Message frequency varies. Text HELP to 83904 or email shop@roomandboard.com for help, STOP to 83904 to end. Msg & Data Rates May Apply. By opting in, I authorize Room & Board to deliver MMS messages using an automatic telephone dialing system and I understand that I am not required to opt in as a condition of purchasing any property, goods, or services. By leaving this box unchecked you will not be opted in for MMS messages at this time. Click to read <a data-qa="opt-in-texts-terms" href="/mobile-terms-and-conditions" target="_blank">Terms and Conditions</a>, and <a data-qa="sign-up-texts-privacy" href="/privacy" target="_blank">Privacy Policy</a>.</div>
				}
				{showSignUpButton &&
					<Button
						data-qa={'opt-in-texts-button'}
						id={'submitFormButton'}
						disabled={disableSignUpBtn}
						variant={isEmailMMSModalVariant ? ButtonVariant.Support : undefined}
					>
						Sign Up for Texts
					</Button>
				}
			</div>
		</form>
	);
});
