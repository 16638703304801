import React from 'react';
import cn from 'classnames';

import { NextJsButtonLink } from '~/components/Buttons/Components/NextJsButtonLink';
import { TextOptInStore } from '~/text-opt-in/Stores/TextOptIn.store';

export const TextOptInSuccess = ({ store }: { store: TextOptInStore }) => {
	const {
		isMMSStandalonePageVariant,
	} = store;

	return (
		<>
			<p className={cn({
				'tw-heading-5 tw-mb-6"': isMMSStandalonePageVariant,
			})} data-qa="opt-in-texts-welcome">Welcome! You're signed up for Room & Board text messages.</p >
			{isMMSStandalonePageVariant &&
				<NextJsButtonLink
					href="/"
					data-qa="sign-up-texts-shop"
				>
					Shop Room & Board
				</NextJsButtonLink>
			}
		</>
	);
};
